<template>
  <div class="w-full">
    <TotalComplaints :total="count" title="Total de Quejas" />
    <ComplaintsFilter
      @fetch-complaints="setQueries"
      :management="true"
      :query="start_query"
      :loading="loading"
    />
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
    <div
      v-if="complaints.length > 0"
      class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 manage-complaints"
    >
      <a-table
        :columns="columns"
        rowKey="id"
        :data-source="complaints"
        :pagination="false"
      >
        <div slot="actions_progress" slot-scope="text, record">
          {{ record.progress_evaluation }} de 4 criterios evaluados
        </div>
        <div slot="actions_watch" slot-scope="text, record">
          <button
            class="flex justify-center col-span-1 text-xs text-blue h-min m-auto"
            @click="goMonitoring(text, record)"
          >
            <a-icon
              type="eye"
              theme="twoTone"
              two-tone-color="#3366cc"
              :style="{
                fontSize: '20px',
              }"
            />
            <b class="my-auto ml-2">Ver</b>
          </button>
        </div>
        <div slot="actions" slot-scope="text, record">
          <BaseButton
            @click.native="managementComplaint(text, record)"
            label="Gestionar"
          />
        </div>
      </a-table>
      <div class="p-4 flex justify-end">
        <a-pagination
          v-model="page"
          :page-size="pageSize"
          :page-size-options="pageSizeOptions"
          :total="count"
          show-size-changer
          size="large"
          @showSizeChange="onShowSizeChange"
        />
      </div>
    </div>
    <div v-if="loading" class="absolute w-full justify-center mt-7">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import ModalConfirmation from "@/components/Dashboard/Users/ModalConfirmation.vue";
import ComplaintStatusLabel from "./ComplaintStatusLabel";
import ComplaintsFilter from "./ComplaintsFilter";
import TotalComplaints from "./TotalComplaints.vue";
import capitalize from "@/utils/checkForms.js";
import moment from "moment";

const columns = [
  {
    title: "Id. de Radicado",
    dataIndex: "codigo_queja",
    key: "codigo_queja",
  },
  {
    title: "Entidad",
    dataIndex: "entity_name",
    key: "entity_name",
  },
  {
    title: "Estado",
    dataIndex: "complaint_state",
    key: "complaint_state",
  },
  {
    title: "Queja principal",
    dataIndex: "complaint_type",
    key: "complaint_type",
  },
  {
    title: "Monitoreo",
    dataIndex: "actions_progress",
    key: "actions_progress",
    scopedSlots: { customRender: "actions_progress" },
  },
  {
    title: "Histórico de monitoreos",
    dataIndex: "actions_watch",
    key: "actions_watch",
    scopedSlots: { customRender: "actions_watch" },
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
  },
];

export default {
  components: {
    ModalConfirmation,
    ComplaintsFilter,
    TotalComplaints,
  },
  data() {
    return {
      columns,
      form: {},
      legend: "",
      count: 0,
      complaints: [],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      page: 1,
      showModal: false,
      pageSize: 100,
      totalCriteria: 0,
      start_query: {},
      pageSizeOptions: ["50", "100", "150", "200", "250"],
    };
  },
  created() {
    
    if ( !this.form.start_date || this.form.start_date == "" ) {
      const start_date = moment()
        .set(new Date())
        .subtract(7, "d")
        .format("YYYY-MM-DD");
      const end_date = moment().set(new Date()).format("YYYY-MM-DD");

      this.form.start_date = start_date;
      this.form.end_date = end_date;
    }

    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 100;
    }

    this.undoneQuery();
    this.fetchComplaints();
    this.totalCriteria = this.$store.state.form.optionsCriterions.length;
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.fetchComplaints();
    },
  },
  methods: {
    goMonitoring(checked, record) {
      this.$router.push(
        `/dashboard/complaints/management/monitoring/${record.codigo_queja}/`
      );
    },
    managementComplaint(checked, record) {
      this.$router.push(
        `/dashboard/complaints/management/detail/${record.codigo_queja}/`
      );
    },
    setQueries(myForm) {
      this.form = myForm;
      this.showToast("info", "Buscando resultados...");
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.updateRouterQuery();
        this.fetchComplaints();
      }
      this.page = 1;
    },
    undoneQuery() {
      if (this.$route.query?.params) {
        let queryArray = this.$route.query?.params.split("&");
        queryArray.forEach((element) => {
          let param = element.split("=");
          this.form[param[0]] = param[1];
        });
        this.start_query = this.form;
      }
    },
    makeQuery() {
      if (this.form) {
        let query_params = Object.keys(this.form)
          .map((key) => {
            if (this.form[key] && this.form[key] !== "") {
              return key + "=" + this.form[key];
            }
          })
          .filter((data) => !!data)
          .join("&");
        return query_params;
      }
      return null;
    },
    async fetchComplaints() {
      let query = `?page_size=${this.pageSize}&page=${this.page}`;

      let query_params = this.makeQuery();
      query += query_params ? `&${query_params}` : "";

      this.loading = true;

      let { error, data } = await this.$api.getManagementComplaint(query);
      this.loading = false;

      if (error) {
        this.complaints = [];
        this.showToast("error", "Ocurrió un error con la consulta");
      }
      if (data) {
        this.count = data.count;
        if (this.count != 0) {
          this.legend = "";
          this.complaints = data.results.map((complaint) => {
            if (complaint.codigo_queja_principal)
              complaint.estado_duplicidad = complaint.codigo_queja_principal =
                "Unificada en " + complaint.codigo_queja_principal;
            else if (complaint.estado_duplicidad === "Duplicada")
              complaint.estado_duplicidad = "Duplicada";
            else complaint.estado_duplicidad = "Es queja principal";
            return {
              ...complaint,
              entity_name: capitalize(complaint.entity_name),
              complaint_type: (
                <ComplaintStatusLabel status={complaint.estado_duplicidad} />
              ),

              complaint_state: (
                <ComplaintStatusLabel
                  status={complaint.complaint_state}
                  danger="true"
                />
              ),
            };
          });
        } else {
          this.legend = "No se encontraron resultados";
          this.complaints = [];
        }
      }
    },
    updateRouterQuery() {
      this.$router
        .replace({
          query: {
            page: this.page,
            params: this.makeQuery(),
          },
        })
        .catch((error) => error);
    },
    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      this.fetchComplaints();
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>
<style>
.manage-complaints .ant-table-thead > tr > th {
  font-weight: bold;
  text-align: center;
  color: #6b7280;
}
</style>
